import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";

const MdxPage = ({ data }: PageProps<{ mdx: { frontmatter: { title: string }; body: string } }>) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className="container mx-auto px-4">
        <h1>{data.mdx.frontmatter.title}</h1>
        <div className="mdxPage">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
export default MdxPage;
